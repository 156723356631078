import React from 'react';

import LabelField from './LabelField';
import { getDefinedValue, getDefinedItem } from '../../util/tools';

class Field extends React.Component {

	field = null;
	readOnly = false;
	resource = null;

	/*
		plain                      mandatory
		instance                   mandatory
		parentField                optional
		path                       mandatory
		readOnly
		ignoreTitle
	*/
	constructor(props) {
		super(props);
		this.init();
		this.state = {
			readOnly: getDefinedValue( props.readOnly, false)
		}
	}
	processProps() {
		this.readOnly = getDefinedValue( this.props.readOnly, false);
		if( this.props.instance ) {
			if( this.props.parentField ) {
				this.field = this.props.instance.getField(this.props.path, this.props.parentField );
			} else {
				this.field = this.props.instance.getField(this.props.path);
			}
		}
	}
	
	componentDidUpdate(prevProps, prevState) {
		let doInit = this.evaluateDoInit(prevProps, prevState);
		let updateListener = ( prevProps.instance !== this.props.instance );
//		if( this.props.path == '/reportItem' ) 
//			console.log( 'componentDidUpdate', prevProps, this.props, doInit, updateListener )
		if ( doInit ) {
			this.init();
			this.setState({ value: getDefinedItem( this, ['field', 'value'], '') });
		}
		if( updateListener ) {
			if( prevProps.instance )
				prevProps.instance.removeChangeListener( this );
			if( this.props.instance )
				this.props.instance.addChangeListener( this );
		}
		if( prevProps.readOnly !== this.props.readOnly )
			this.setState({ readOnly: getDefinedValue( this.props.readOnly, false) });
		this.completeComponentDidUpdate(prevProps, prevState, doInit);
		this.validateInit();
	}
	evaluateDoInit(prevProps, prevState) {
		let doInit = false;
		doInit = doInit || prevProps.instance !== this.props.instance;
		doInit = doInit || prevProps.parentField !== this.props.parentField;
		doInit = doInit || prevProps.path !== this.props.path;
		return doInit;
	}
	completeComponentDidUpdate(prevProps, prevState, doInit) {
		
	}
	componentDidMount() {
		if( this.props.instance ) 
			this.props.instance.addChangeListener( this );
		this.validateInit();
	}
	componentWillUnmount() {
		if( this.props.instance ) 
			this.props.instance.removeChangeListener( this );
  	}
	init() {
		if( this.props.instance ) {
			if( this.props.parentField ) {
				this.field = this.props.instance.getField(this.props.path, this.props.parentField );
			} else {
				this.field = this.props.instance.getField(this.props.path);
			}
		}
		this.completeInit();
	}
	completeInit() {
		
	}
	validateInit() {
		if( ! this.field ) {
			console.log( 'Unknown field (instance, parentField, path)', 
					this.props.instance, this.props.parentField, this.props.path )
		}
	}
	
	createDivUnknownField() {
		let parentHtml = null;
		if( this.props.parentField ) {
			parentHtml = (<div> Parent: {this.props.instance.getFieldPath(this.props.parentField)} </div>)
		}
		return (
			<div style={{margin: '10px', padding: '10px', border: '1px solid blue', backgroundColor: 'lightYellow'}}>
				<div>Unknown field: [{this.props.path}]</div>
				{parentHtml}
			</div>
		)
	}
	createLabel() {
		let instance = this.props.instance;
		let path = this.props.path;
		let parentField = this.props.parentField;

		let labelHtml = null;
		let ignoreTitle = getDefinedValue(this.props.ignoreTitle, false);
		if( ! ignoreTitle ) {
			let labelStyle = Object.assign({}, {divMain: {marginRight: '10px'}}, 
				getDefinedItem(this.props, ['styles', 'label'], {}))
			labelHtml = (
				<LabelField instance={instance} path={path} parentField={parentField} 
					styles={labelStyle}
				/>
			);
		}
		return labelHtml;		
	}
}

export default Field;
