import React from 'react';
import styles from './TableView.module.css';

import TableHeader from './TableHeader';
import TableBody from './TableBody';

import { getCurrentResource } from '../../util/tools';

class TableView extends React.Component {

	constructor(props) {
		super(props);
		props.rateView.componentTableView = this;
		
		this.resource = getCurrentResource().components.tableview.TableView;
		this.componentTableHeader = null;
		this.componentTableBody = null;
	}
	
	markPage = () => {
		if( this.componentTableBody != null )
			this.componentTableBody.markPage();
	}

	render() {
		const {rateView, tableHeaders, tableData} = this.props;
		return (
			<table className={styles.tableTableView} border="1">
				<TableHeader rateView={rateView} tableView={this} tableHeaders={tableHeaders} />
				<TableBody rateView={rateView} tableView={this} tableData={tableData} />
				<AggregationRow row={this.props.rateView.pageData.rowSum} title={this.resource.aggregationSum}/>
				<AggregationRow row={this.props.rateView.pageData.rowAvg} title={this.resource.aggregationAvg}/>
				<AggregationRow row={this.props.rateView.pageData.rowMin} title={this.resource.aggregationMin}/>
				<AggregationRow row={this.props.rateView.pageData.rowMax} title={this.resource.aggregationMax}/>
			</table>
		);
	}
}

function AggregationRow(props) {
	var row = props.row;
	var title = props.title;
	var rowStyle = {backgroundColor: 'lightyellow'};
	
	if( ! row.checked )
		return null;
	
	return (
		<tr style={rowStyle}>
			<td>{title}</td>
			{
				row.row.map((cell, index) => {
					let cssStyle = JSON.parse( cell.cssStyle );
					if( ! cssStyle.textAlign )
						cssStyle.textAlign = 'right';
					let cellText = cell.text;
					if( cell.type == 'html' ) {
						cellText = ( <span dangerouslySetInnerHTML={{__html: cell.text}} /> );
					}
					return (
						<td key={index} valign="top">
							<div style={cssStyle}>{cellText}</div>
						</td>
					);
				})
			}
		</tr>
	);
}

export default TableView;
