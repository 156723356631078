import React from 'react';
import Modal from 'react-responsive-modal';

import stylesPlain from './plain.module.css';

import config from '../../config/config';

import { Realm } from '../../util/realm/realm';
import { Instance, DATATYPE_FILE } from '../../util/realm/instance';
import { FILE_WAS_SET, FILE_WAS_CLEARED } from '../../components/plain/FieldFile';

import { getActualRealm } from './utilPlain';
import { getCurrentResource } from '../../util/tools'; 
import { 
	performApiRequest, performApiRequestForm, 
	MASTER_LOAD_OBJECT_API_URL, ENTITY_LOAD_BODY_API_URL, ENTITY_LOAD_API_URL, 
	ENTITY_LOAD_PLAIN_API_URL, ENTITY_SAVE_API_URL 
} from '../../util/restapi'; 
import { getUserInfo } from '../../util/localStorage';

import iconSpin from '../../assets/icons/spinner-snake.gif';
import iconSave from '../../assets/icons/png24x24/Save.png';

export const FORM_STATE_INIT_START = 'start';
export const FORM_STATE_INIT_COMPLETE = 'complete';
export const FORM_STATE_INIT_ERROR = 'error';

export const MODAL_DIALOG_STATE_WORKING = 'working';
export const MODAL_DIALOG_STATE_COMPLETE = 'complete';

export const SYSTEM_DATA_STATE_HIDDEN = 'hidden';
export const SYSTEM_DATA_STATE_FETCHING = 'fetching';
export const SYSTEM_DATA_STATE_COMPLETE = 'complete';

export const PLAIN_WIDTH = '1400px';

class PlainPage extends React.Component {
	
	instanceCunter = 0;

	resource = null;
	realm = null;
	instanceModel = null;
	instance = null;
	instanceReadOnly = false;

	instanceMaster = null; // System data
	body = '';
	
	formPath = '';
	formTitle = '';
	
	reloadPageAfterSave = false;
	reloadInstanceId = null;

	state = {
		formState: FORM_STATE_INIT_START,
		message: '',
		instance: null,
		instanceCard: '',
		plainDirtyCounter: 0,
		systemDataState: SYSTEM_DATA_STATE_HIDDEN,
		isModalDialogOpen: false,
		modalDialog: { 
			dialogState: MODAL_DIALOG_STATE_COMPLETE,
			dialogTitle: '',
			dialogMessage: '',
			additionalMessage: ''
		},
	}

	buildInstanceCard = null;
	
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.plain.PlainPage;		
	}

	init = async (classId) => {
		let response = await getActualRealm();
		if( ! response.success ) {
			this.setState({formState: FORM_STATE_INIT_ERROR, message: response.message});
			return;
		}
		this.realm = new Realm(response.data);
		this.instanceModel = this.realm.getInstanceModel(classId);
		if( this.instanceModel == null ) {
			this.setState({formState: FORM_STATE_INIT_ERROR, message: 'Absent instance model for class ' + classId});
			return;
		}
		
		let bean = null;
		var instanceId = this.props.match.params.id;
		if( instanceId > 0) {
			let response = await performApiRequest(ENTITY_LOAD_PLAIN_API_URL, {entityId: instanceId});
			if( ! response.success ) {
				this.setState({formState: FORM_STATE_INIT_ERROR, message: response.message});
				return false;
			}
			bean = response.data.entity;
		}
	
		this.instance = this.createInstanceFromBean(bean);
		this.instance.addChangeListener(this);
		console.log( "Plain. Bean", bean ); 
		console.log( "Plain. Instance", this.instance );
		let completeInitOk = await this.completeInit();
		if( completeInitOk ) {
			let card = '';
			if( this.buildInstanceCard )
				card = this.buildInstanceCard( this.instance );
			this.setState({formState: FORM_STATE_INIT_COMPLETE, instance: this.instance, instanceCard: card});
		}
	}
	createInstanceFromBean(bean) {
		let instance = new Instance(this.instanceModel, bean);
		instance.instanceCunter = ++this.instanceCunter;
		return instance;
	}
	updateInstance(bean) {
		this.instance.removeChangeListener(this);
		this.instance = this.createInstanceFromBean(bean);
		this.instance.addChangeListener(this);
		this.instanceMaster = null;
		this.body = '';
		console.log('Update instance', this.instance)
		this.setState({
			instance: this.instance, systemDataState: SYSTEM_DATA_STATE_HIDDEN,
			plainDirtyCounter: this.state.plainDirtyCounter + 1
		});
	}
	async completeInit() {
		return true;
	}
	
	handleInstanceChange(event) {
		if( this.buildInstanceCard ) {
			let card = this.buildInstanceCard( this.instance );
			if( card != this.state.instanceCard )
				this.setState({instanceCard: card});
		}
	}
	
	openModalDialog = () => {
		this.setState({ 
			isModalDialogOpen: true, 
			modalDialog: { 
				dialogState: MODAL_DIALOG_STATE_WORKING,
				dialogTitle: this.resource.dlgTitle,
				dialogMessage: this.resource.msgPleaseWait
			},
		});
	}
	closeModalDialog = () => {
		if( this.state.modalDialog.dialogState != MODAL_DIALOG_STATE_COMPLETE )
			return;
		this.setState(
			{ 
				isModalDialogOpen: false, 
				modalDialog: { 
					dialogState: MODAL_DIALOG_STATE_COMPLETE,
					dialogTitle: '',
					dialogMessage: ''
				},
			}, 
			this.doAfterCloseModalDialog
		);
	}
	doAfterCloseModalDialog = () => {
		if( this.reloadPageAfterSave ) {
			let url = config.PATH + '/' + this.formPath + '/' + this.reloadInstanceId;
			window.open(url, '_self')
		}
	}
	
	handleCbSystemDataClick = async (e) => {
		let checked = e.target.checked;
		if( ! checked )
			this.setState({systemDataState: SYSTEM_DATA_STATE_HIDDEN})
		else {
			if( this.instanceMaster != null )
				this.setState({systemDataState: SYSTEM_DATA_STATE_COMPLETE})
			else {
				this.setState({systemDataState: SYSTEM_DATA_STATE_FETCHING})

				let instanceId = this.instance.id;
				let response = await performApiRequest( MASTER_LOAD_OBJECT_API_URL, {id: instanceId} );
				if( response.success ) {
					this.instanceMaster = response.data;
					let bodyRequest = {
						id: instanceId,
						className: this.instanceMaster.className,
					};
					response = await performApiRequest(ENTITY_LOAD_BODY_API_URL, bodyRequest);
					if( response.success ) {
						this.body = response.data;
					} else {
						this.body = response.message;
					}
				} else {
					this.body = response.message;
					this.instanceMaster = {
						cardMaster: '',
						id: '',
						globalId: '',
						className: '',
						ownerGroup: '',
						ownerUser: '',
						createUser: '',
						createDate: '',
						modifyUser: '',
						modifyDate: '',
					};
				}
				this.setState({systemDataState: SYSTEM_DATA_STATE_COMPLETE})
				if( ! response.success ) {
					this.props.app.showResponse( response );
				}
			}
		}
	}
	
	save = async () => {
		this.openModalDialog();
		
		let listFileField = this.instance.getFields( (field) => {return field.model.dataType == DATATYPE_FILE} );
		let files = [];
		let fileIndex = 0;
		for(let field of listFileField ) {
			let file = field.value;
			if( file.fileState == FILE_WAS_SET ) {
				file.fileIndex = fileIndex++;
				files.push( file.fileBody );
				file.fileBody = undefined;
			} if( file.fileState == FILE_WAS_CLEARED ) {
				file.fileBody = undefined;
			}
		}
		
		let bean = this.instance.createBean();
		bean.feVersion = 'plain1';
		console.log('Save bean', bean);
		let isNew = bean.id == null || bean.id == 0;
		let jsonInstance = JSON.stringify( bean );
		
		const form = new FormData();
		form.append('jsonInstance', jsonInstance);
		form.append('class', this.instance.model.entityId);
		for(let f of files) {
			form.append('files', f);
		}
		
		let response = await performApiRequestForm(ENTITY_SAVE_API_URL, form);
		console.log('Save response', bean);
		let dlgMessage;
		let additionalMessage = '';
		if( response.success ) {
			let data = JSON.parse( response.data );
			console.log('Save response data', data, isNew);
			if( isNew ) {
				this.reloadInstanceId = data.entity.id;
				this.reloadPageAfterSave = true;
			} else {
				let bean = data.entity;
				console.log('Saved bean', bean);
				this.updateInstance(bean);
			}
			dlgMessage = response.message;
			if( ! dlgMessage )
				dlgMessage = this.resource.msgSaved;
			additionalMessage = response.log;
		} else {
			dlgMessage = response.message + '\n' + response.log;
		}
		
		this.setState({
			modalDialog: { 
				dialogState: MODAL_DIALOG_STATE_COMPLETE,
				dialogTitle: this.resource.dlgTitle,
				dialogMessage: dlgMessage,
				additionalMessage: additionalMessage
			},
		})
	}
	
	renderBody() {
		return null;
	}
	
	renderHeader() {
		let userInfo = getUserInfo();
		let cbSystemData = null;
		if( userInfo.permission.superuser && this.instance.id ) {
			cbSystemData = (
				<React.Fragment>
					<input type='checkbox' checked={this.state.systemDataState != SYSTEM_DATA_STATE_HIDDEN} 
						onChange={this.handleCbSystemDataClick}
						style={{marginLeft: '15px'}}
					/>
					<span style={{marginLeft: '5px', width: '200px'}}>{this.resource.labelCbSystemData}</span>
				</React.Fragment>
			)
		}
		let instanceCardHtml = null;
		if( this.buildInstanceCard ) {
			instanceCardHtml = (
				<div style={{marginLeft: '30px', fontSize: '14pt', fontStyle: 'italic', color: 'blue'}}>
					{this.state.instanceCard}
				</div>
			);
		}
		return (
			<div style={{
				position: 'sticky', top: '0', opacity: '1', backgroundColor: 'white', zIndex: '1',
				display: 'flex', marginTop: '15px', paddingBottom: '10px', alignItems: 'center'
			}}>
				<span style={{marginRight: '15px', fontSize: '16pt', fontWeight: 'bold'}}>{this.formTitle}</span>
				<button type='button' className={stylesPlain.buttonIcon} 
					onClick={this.save} title={this.resource.buttonSave}
					disabled={this.instanceReadOnly}
				>
					<img src={iconSave} />
				</button>
				{cbSystemData}
				{instanceCardHtml}
			</div>
		);
	}
	renderSystemData() {
		if( this.state.systemDataState == SYSTEM_DATA_STATE_HIDDEN )
			return null;
		if( this.state.systemDataState == SYSTEM_DATA_STATE_FETCHING )
			return (
				<div style={{width: PLAIN_WIDTH, margin: 'auto', marginTop: '10px', border: '1px solid black'}}>
					<div style={{textAlign: 'left', margin: '15px'}}>
						{this.resource.labelSystemData}
					</div>
					<div style={{textAlign: 'center', margin: '15px'}}>
						{this.resource.msgFetching}
						<div style={{marginTop: '40px', marginBottom: '20px'}}>
							<img src={iconSpin} />
						</div>
					</div>
				</div>
			);
		if( this.state.systemDataState == SYSTEM_DATA_STATE_COMPLETE ) {
			let master = this.instanceMaster;
			let divFieldSystemData = {display: 'flex', alignItems: 'center', marginTop: '3px' };
			let divLabel = {
				fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
				fontSize: '12pt',
				paddingRight: '5px',
				width: '200px'	
			};
			return (
				<div style={{width: PLAIN_WIDTH, margin: 'auto', marginTop: '10px', border: '1px solid black'}}>
					<div style={{textAlign: 'left', margin: '15px'}}>
						{this.resource.labelSystemData}
					</div>
					<div style={{textAlign: 'left', margin: '15px'}}>
						<div style={divFieldSystemData}>
							<div style={divLabel}>{this.resource.labelCard}</div>
							<div>{master.cardMaster}</div>
						</div>
						<div style={divFieldSystemData}>
							<div style={divLabel}>Id</div>
							<div>{master.id}</div>
						</div>
						<div style={divFieldSystemData}>
							<div style={divLabel}>GlobalId</div>
							<div>{master.globalId}</div>
						</div>
						<div style={divFieldSystemData}>
							<div style={divLabel}>{this.resource.labelClass}</div>
							<div>{master.className}</div>
						</div>
						<div style={divFieldSystemData}>
							<div style={divLabel}>{this.resource.labelOwnerGroup}</div>
							<div>{master.ownerGroup}</div>
						</div>
						<div style={divFieldSystemData}>
							<div style={divLabel}>{this.resource.labelOwnerUser}</div>
							<div>{master.ownerUser}</div>
						</div>
						<div style={divFieldSystemData}>
							<div style={divLabel}>{this.resource.labelCreateUser}</div>
							<div>{master.createUser}</div>
						</div>
						<div style={divFieldSystemData}>
							<div style={divLabel}>{this.resource.labelCreateTime}</div>
							<div>{master.createDate}</div>
						</div>
						<div style={divFieldSystemData}>
							<div style={divLabel}>{this.resource.labelModifyUser}</div>
							<div>{master.modifyUser}</div>
						</div>
						<div style={divFieldSystemData}>
							<div style={divLabel}>{this.resource.labelModifyTime}</div>
							<div>{master.modifyDate}</div>
						</div>
						<div>
							<div style={divLabel}>Body</div>
							<div style={{border: '1px solid green',
								fontFamily: 'Verdana, Arial, Helvetica, sans-serif', fontSize: '12pt', 
								padding: '5px', height: '600px', overflow: 'scroll', }}
							>
								<pre>{this.body}</pre>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
	
	renderAbnormalInit = () => {
		if( this.state.formState == FORM_STATE_INIT_START ) {
			return (
				<div style={{width: PLAIN_WIDTH, margin: 'auto', textAlign: 'left'}}>
					<div style={{marginTop: '15px', marginBottom: '10px', textAlign: 'left'}}>
						<span style={{marginRight: '15px', fontSize: '16pt', fontWeight: 'bold'}}>{this.formTitle}</span>
						<span style={{marginRight: '15px'}}>{this.resource.msgActivateForm}</span>
					</div>
				</div>
			);
		}
		if( this.state.formState == FORM_STATE_INIT_ERROR ) {
			return (
				<div style={{width: PLAIN_WIDTH, margin: 'auto', textAlign: 'left'}}>
					<div style={{marginTop: '15px', marginBottom: '10px', textAlign: 'left'}}>
						<span style={{marginRight: '15px', fontSize: '16pt', fontWeight: 'bold'}}>{this.formTitle}</span>
						<span style={{marginRight: '15px'}}>{this.state.message}</span>
					</div>
				</div>
			);
		}
	}
	renderException = (ex) => {
		return (
			<div style={{width: PLAIN_WIDTH, margin: 'auto', textAlign: 'left'}}>
				<div style={{marginTop: '15px', marginBottom: '10px', textAlign: 'left'}}>
					<span style={{marginRight: '15px', fontSize: '16pt', fontWeight: 'bold'}}>{this.formTitle}</span>
					<span style={{marginRight: '15px'}}>Exception</span>
				</div>
			</div>
		);
	}
	
	render() {
		if( this.state.formState != FORM_STATE_INIT_COMPLETE ) {
			return this.renderAbnormalInit();
		}
		try {
			return(
				<div>
					{this.renderBody()}
					{this.renderSystemData()}
					
					<ModalDialog 
						isModalOpen={this.state.isModalDialogOpen} 
						close={this.closeModalDialog}
						holder={this} 
						message={null} 
					/>
				</div>
			);
		} catch (err) {
			console.log('Error', err);
			return this.renderException( err );
		}
	}

}

class ModalDialog extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		let holder = this.props.holder;


		let bodyHtml = null;
		if( holder.state.modalDialog.dialogState == MODAL_DIALOG_STATE_WORKING ) {
			bodyHtml = (
				<div>
					<div>
						{holder.state.modalDialog.dialogMessage}
					</div>
					<div style={{marginTop: '40px', marginBottom: '20px'}}>
						<img src={iconSpin} />
					</div>
				</div>
			);
		} else {
			let divAdditionalMessageHtml = null;
			if( holder.state.modalDialog.additionalMessage ) {
				divAdditionalMessageHtml = (
					<div style={{margin: '20px auto 30px auto', width: '95%'}}>
						<textarea style={{width: '100%'}} rows="8" readOnly={true}>
							{holder.state.modalDialog.additionalMessage}
						</textarea>
					</div>		
				);
			}
			bodyHtml = (
				<div>
					<div style={{marginTop: '20px', marginBottom: '20px'}}>
						<div>
							{holder.state.modalDialog.dialogMessage}
						</div>
						{divAdditionalMessageHtml}
					</div>
				</div>
			);
		}
		return (
			<Modal open={this.props.isModalOpen} onClose={this.props.close} center styles={styleModal} >
				<div style={localStyles.divHeader}>
					{holder.state.modalDialog.dialogTitle}
				</div>
				<div style={localStyles.divBody}>
					{bodyHtml}
				</div>
			</Modal>
		);
	}
}

const styleModal = {
	modal: {
		background: '#fff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '600px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#F00',
		outline: 'none',
	},
};
const localStyles ={
	button: {
		marginLeft: '40px',
		marginRight: '40px',
		padding: '5px 15px 5px 15px',
		fontSize: '16pt'
	},
	divHeader: {
		fontSize: '14pt',
		textAlign: 'center',
		padding: '10px',
		borderRadius: '21px 21px 0 0',
		backgroundColor: '#F0F0F0',
	},
	divBody: {
		fontSize: '14pt',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '10px',
		marginBottom: '10px',
		textAlign: 'center',
		padding: '5px'
	},
	divAdditionalMessage: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: '30px',
		width: '95%'
	}
};

export default PlainPage;
