export const ENUMERATION_BANK_LIST = 'bn';
export const ENUMERATION_CONTRIBUTOR_TYPE = 'ct';
export const ENUMERATION_LANGUAGE = 'lg';
export const ENUMERATION_MATERIAL_TYPE = 'ma';
export const ENUMERATION_SHARE_TYPE = 'sh';
export const ENUMERATION_TEXT_FORMAT = 'tf';
export const ENUMERATION_TITLE_TYPE = 'tt';
export const ENUMERATION_DERIVATIVE_TYPE = 'de';
export const ENUMERATION_HONOR_TYPE = 'ho';
export const ENUMERATION_PAGE_TYPE = 'pt';
export const ENUMERATION_PRODUCT_FORM_DETAIL = 'fd';
export const ENUMERATION_PRICE_QUALIFIER = 'pq';

export const STRING_FORMAT_INTEGER = "int";
export const STRING_FORMAT_DECIMAL = "dec";
export const STRING_FORMAT_FORBIDDEN_VALUES = "fbdval";
export const STRING_FORMAT_ISBN = "isbn";

export const FILE_WAS_SET = "wasSet";
export const FILE_WAS_CLEARED = "wasCleared";
