import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';

import { getCurrentResource, getTwoDigitNumber, findByKey } from '../../util/tools';
import { canPerformAction } from '../../util/localStorage';
import { ACTION_EBOOK_PAYMENT_IMPORT, ACTION_EBOOK_PAYMENT_DOWNLOAD } from '../../util/consts/actions';
import { FORM_EBOOK_PAYMENT_TABLE } from '../../util/consts/forms';
import { 
	performApiRequest, performApiRequestForm, 
	ACTION_EBOOK_PAYMENT_IMPORT_API_URL, ACTION_EBOOK_PAYMENT_DOWNLOAD_API_URL 
} from '../../util/restapi';
import { ENUMERATION_BANK_LIST } from '../../util/consts/common'; 

import iconImport from '../../assets/icons/png24x24/get_message.png';
import iconDownload from '../../assets/icons/png24x24/download_file.png';

const MODAL_MODE_ACTIVE = 'a';
const MODAL_MODE_WAIT = 'w';
const MODAL_MODE_COMPLETE = 'c';
const MODAL_MODE_ERROR = 'e';

class EBookPaymentListPage extends Component {
	
	constructor(props) {
		super(props);
		this.refRateView = React.createRef();
		this.refImportModal = React.createRef();
		this.refDownloadModal = React.createRef();
		this.state = {
			isImportModalOpen: false,
			isDownloadModalOpen: false,
		}

		this.resource = getCurrentResource().pages.tableform.EBookPaymentList;
		var paramCacheId = props.match.params.param;

		this.rvContext = {
			form: FORM_EBOOK_PAYMENT_TABLE,
			data: []
		};
		this.rvOptions = {
			form: FORM_EBOOK_PAYMENT_TABLE,
			formTitle: this.resource.formTitle,
			paramCacheId: paramCacheId,
			transition: null,
			controlPanelTools: this.controlPanelTools(),
			hideButtonAdd: true,
			hideButtonEdit: true,
			hideButtonMergeInstances: true,
			hideButtonChangeOwner: true,
			hideButtonFieldUpdate: true,
			hideButtonGlobalIdUpdate: true,
			hideButtonDelete: true,
			hideButtonShowHtml: true,
			hideButtonHtml: true,
			hideButtonPdf: true,
			hideButtonXml: true,
			//hideButtonTransition: true,
			hideButtonUshExport: true,
			hideButtonUshImport: true,
		};
	}
	controlPanelTools = () => {
		return (
			<div style={{display: 'flex', marginLeft: '20px'}}>
				{/*
				<ControlPanelButtonImport holder={this} />
				*/}
				<ControlPanelButtonDownload holder={this} />
			</div>			
		);
	}
	openImportModal = () => {
		this.refImportModal.current.initModal();
		this.setState({ isImportModalOpen: true });	
	}
	closeImportModal = () => {
		this.setState({ isImportModalOpen: false });	
	}
	openDownloadModal = () => {
		this.refDownloadModal.current.initModal();
		this.setState({ isDownloadModalOpen: true });	
	}
	closeDownloadModal = () => {
		this.setState({ isDownloadModalOpen: false });	
	}

	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView ref={this.refRateView} rvContext={this.rvContext} rvOptions={this.rvOptions} app={this.props.app}  />
				<ImportModal ref={this.refImportModal} page={this} 
					isModalOpen={this.state.isImportModalOpen} 
					close={this.closeImportModal} 
				/>
				<DownloadModal ref={this.refDownloadModal} page={this} 
					isModalOpen={this.state.isDownloadModalOpen} 
					close={this.closeDownloadModal} 
				/>
			</React.Fragment>
		)
	}
	
}

class ImportModal extends React.Component {

	constructor(props) {
		super(props);
		this.resource = props.page.resource;
		this.fileImportRef = React.createRef();
		this.file = null;
		this.resultMessage = '';
		this.resultLog = '';
		this.state = {
			modalMode: MODAL_MODE_ACTIVE,
			fileName: this.resource.textSelectFile
		}
	}
	initModal = () => {
		this.file = null;
		this.resultMessage = '';
		this.resultLog = '';
		this.setState({
			modalMode: MODAL_MODE_ACTIVE,
			fileName: this.resource.textSelectFile
		});
	}
	tryAgain = () => {
		this.setState({	modalMode: MODAL_MODE_ACTIVE});
	}

	handleFileSelect = (e) => {
		var files = e.target.files;
		if( files && files.length > 0 ) {
			this.file = files[0];
			this.setState({fileName: this.file.name})
		}
	}	

	startImport = async () => {
		this.setState({modalMode: MODAL_MODE_WAIT})
		const form = new FormData();
		form.append('format', 'xml');
		form.append('file', this.file);
		var response = await performApiRequestForm(ACTION_EBOOK_PAYMENT_IMPORT_API_URL, form);
		if( response.success ) {
			this.resultMessage = response.message;
			this.resultLog = response.log;
			if( this.resultMessage == '' ) {
				this.resultMessage = this.resource.messageImportComplete;
			} 
		} else {
			this.resultMessage = response.message; 
			this.resultLog = response.log;
		}
		this.setState({modalMode: MODAL_MODE_COMPLETE})
	}

	render() {
		var resource = this.resource;
		if( this.state.modalMode == MODAL_MODE_COMPLETE ) {
			var divAdditionalMessage = null;
			if( this.resultLog ) {
				var divAdditionalMessage = (
					<div style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: '30px', width: '95%'}}>
						<textarea style={{width: '100%'}} rows="8" readOnly={true}>
							{this.resultLog}
						</textarea>
					</div>		
				);
			}
			var modalBodyHtml = (
				<React.Fragment>
					<div style={{color: 'red', textAlign: 'center', padding: '30px', fontSize: '16pt'}} 
						dangerouslySetInnerHTML={{__html: this.resultMessage}}
					/>
					{divAdditionalMessage}
					<div style={localStyles.divButtons}>
						<button type='button' onClick={this.tryAgain} >
							{resource.buttonTryAgain}
						</button>
					</div>
				</React.Fragment>
			);
		} else {
			var buttonImportDisabled = this.state.modalMode == MODAL_MODE_WAIT || this.file == null;
			var divWaiting = this.state.modalMode == MODAL_MODE_WAIT ?
				(<div style={localStyles.divWaiting}>{resource.waiting}</div>) : null;  
			var modalBodyHtml = (
				<div style={{margin: '15px'}}>
					<div>
						{resource.labelFile}: 
						<span style={{marginLeft: '5px', marginRight: '10px'}}>{this.state.fileName}</span> 
						<button onClick={e => this.fileImportRef.current.click()}>...</button>
						<input ref={this.fileImportRef} type={'file'}  style={{display: 'none'}} 
							onChange={e => this.handleFileSelect(e)} 
						/>
					</div>
					{divWaiting}
					<div style={localStyles.divButtons}>
						<button type='button' title={resource.buttonImportTooltip}
							onClick={this.startImport} disabled={buttonImportDisabled}
						>
							{resource.buttonImport}
						</button>
					</div>
				</div>
			);
		}
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center 
				styles={styleModal} closeOnOverlayClick={false} animationDuration={1} 
			>
				<div>
					<div style={localStyles.divHeader}>
						{this.resource.modalImportTitle}
					</div>
					{modalBodyHtml}
				</div>
			</Modal>
		);
	}
}
class DownloadModal extends React.Component {

	constructor(props) {
		super(props);
		this.resource = props.page.resource;
		this.resultMessage = '';
		this.resultLog = '';
		this.bankList = [];
		let d = new Date();
		d.setDate(d.getDate() - 1);
		let inputDate = '' + d.getFullYear() + getTwoDigitNumber(d.getMonth() + 1) + getTwoDigitNumber(d.getDate());
		this.state = {
			modalMode: MODAL_MODE_ACTIVE,
			inputDateFrom: inputDate,
			inputDateTo: inputDate,
		}
	}
	initModal = () => {
		this.resultMessage = '';
		this.resultLog = '';
		this.rateView = this.props.page.refRateView.current;
		this.enumBankList = findByKey(this.rateView.formInfo.listEnumeration, 'enumerationId', ENUMERATION_BANK_LIST)
		this.setState({
			modalMode: MODAL_MODE_ACTIVE,
		});
	}
	tryAgain = () => {
		this.setState({	modalMode: MODAL_MODE_ACTIVE});
	}

	handleBankSelect = e => {
		this.bankList = [];
		for(let o of e.target.selectedOptions) {
			this.bankList.push(o.value);
		}
	}
	handleValueChange = e => {
		let {name, value} = e.target;
		if( name == 'from' ) {
			if( value )
				value = value.slice(0,4) + value.slice(5,7) + value.slice(8,10);
			this.setState({inputDateFrom: value});
		}
		if( name == 'to' ) {
			if( value )
				value = value.slice(0,4) + value.slice(5,7) + value.slice(8,10);
			this.setState({inputDateTo: value});
		}
	}

	startDownload = async () => {
		this.setState({modalMode: MODAL_MODE_WAIT});
		let request = {bankList: this.bankList, dateFrom: this.state.inputDateFrom, dateTo: this.state.inputDateTo};
		let response = await performApiRequest(ACTION_EBOOK_PAYMENT_DOWNLOAD_API_URL, request);
		if( response.success ) {
			this.resultMessage = response.message;
			this.resultLog = response.log;
			if( this.resultMessage == '' ) {
				this.resultMessage = this.resource.messageImportComplete;
			} 
		} else {
			this.resultMessage = response.message; 
			this.resultLog = response.log;
		}
		this.setState({modalMode: MODAL_MODE_COMPLETE})
	}

	render() {
		let resource = this.resource;
		let modalBodyHtml = null;
		if( this.state.modalMode == MODAL_MODE_COMPLETE ) {
			let divAdditionalMessage = null;
			if( this.resultLog ) {
				divAdditionalMessage = (
					<div style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: '30px', width: '95%'}}>
						<textarea style={{width: '100%'}} rows="8" readOnly={true}>
							{this.resultLog}
						</textarea>
					</div>		
				);
			}
			modalBodyHtml = (
				<React.Fragment>
					<div style={{color: 'red', textAlign: 'center', padding: '30px', fontSize: '16pt'}} 
						dangerouslySetInnerHTML={{__html: this.resultMessage}}
					/>
					{divAdditionalMessage}
					<div style={localStyles.divButtons}>
						<button type='button' onClick={this.tryAgain} >
							{resource.buttonTryAgain}
						</button>
					</div>
				</React.Fragment>
			);
		} else {
			let buttonDownloadDisabled = this.state.modalMode == MODAL_MODE_WAIT;
			let divWaiting = this.state.modalMode == MODAL_MODE_WAIT ?
				(<div style={localStyles.divWaiting}>{resource.waiting}</div>) : null;  
			let valueFrom = this.state.inputDateFrom;
			if( valueFrom != null && valueFrom.length == 8) {
				valueFrom = valueFrom.slice(0,4) + '-' + valueFrom.slice(4,6) + '-' + valueFrom.slice(6,9);
			}
			let valueTo = this.state.inputDateTo;
			if( valueTo != null && valueTo.length == 8) {
				valueTo = valueTo.slice(0,4) + '-' + valueTo.slice(4,6) + '-' + valueTo.slice(6,9);
			}
			let bankOptionsHtml = null;
			if( this.enumBankList )
				bankOptionsHtml = this.enumBankList.items.map((item, index) => {
					return (<option key={index} value={item.value}>{item.label}</option>)
				});
			modalBodyHtml = (
				<div style={{margin: '15px'}}>
					<div style={{display: 'flex'}}>
						<div style={{flexGrow: '1'}}>
							<div>
								Банк
							</div>
							<select multiple={true} size={10} style={{width: '200px'}} onChange={this.handleBankSelect}>
								{bankOptionsHtml}
							</select>
						</div>
						<div style={{flexGrow: '1'}}>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<span>{resource.labelDateFrom}</span>
								<input type='date'name='from' style={{marginLeft: '10px'}}
									value={valueFrom} onChange={this.handleValueChange}
								/>
							</div>
							<div style={{display: 'flex', marginTop: '15px', alignItems: 'center'}}>
								<span>{resource.labelDateTo}</span>
								<input type='date'name='to' style={{marginLeft: '10px'}}
									value={valueTo} onChange={this.handleValueChange}
								/>
							</div>
						</div>
					</div>
					{divWaiting}
					<div style={localStyles.divButtons}>
						<button type='button' title={resource.buttonDownloadTooltip}
							onClick={this.startDownload} disabled={buttonDownloadDisabled}
						>
							{resource.buttonDownload}
						</button>
					</div>
				</div>
			);
		}
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center 
				styles={styleModal} closeOnOverlayClick={false} animationDuration={1} 
			>
				<div>
					<div style={localStyles.divHeader}>
						{this.resource.modalDownloadTitle}
					</div>
					{modalBodyHtml}
				</div>
			</Modal>
		);
	}
}

function ControlPanelButtonImport(props) {
	
	if( canPerformAction( ACTION_EBOOK_PAYMENT_IMPORT ) ) {
		return (
			<button type='button' style={{marginLeft: '5px', width: '36px', height: '30px'}}
				 onClick={props.holder.openImportModal}
				 title={props.holder.resource.buttonImportTooltip} 
			>
				<img src={iconImport} />
			</button>			
		);
	} else {
		return null;
	}
}
function ControlPanelButtonDownload(props) {
	
	if( canPerformAction( ACTION_EBOOK_PAYMENT_DOWNLOAD ) ) {
		return (
			<button type='button' style={{marginLeft: '5px', width: '36px', height: '30px'}}
				 onClick={props.holder.openDownloadModal}
				 title={props.holder.resource.buttonDownloadTooltip} 
			>
				<img src={iconDownload} />
			</button>			
		);
	} else {
		return null;
	}
}

const styleModal = {
	modal: {
		background: '#ffffff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '700px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#FF0000',
		outline: 'none',
	},
};

const localStyles = {
	divHeader: {
		fontSize: '16pt',
		textAlign: 'center',
		padding: '10px',
		borderRadius: '21px 21px 0 0',
		backgroundColor: '#F0F0F0'
	},
	divParameters: {
		marginTop: '10px',
		padding: '10px'
	},
	divWaiting: {
		textAlign: 'center',
		padding: '7px'
	},
	divButtons: {
		marginTop: '7px',
		padding: '10px',
		marginRight: '15px',
		textAlign: 'right'
	}
};

export default EBookPaymentListPage;
